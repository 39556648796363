import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import TripleColumnBlock from "@components/tripleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import Blockquote from "@components/blockquote"
import ReactPlayer from 'react-player'

import {
  QuickLinkButton,
} from "@components/buttons"

import {
  LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout>
      <SEO
        title="App Developer Denver Colorado - Intrix Case Study - Websuasion"
        ogDescription="Are you looking for a professional app developer serving the Denver, CO area of Atlanta? See how we enabled Intrix to achieve their app developement goals."
        image="/images/case-studies/denver-colorado-app-development.jpg"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>App Developer Denver Colorado</h1>
						<h3>A Case Study of Intrix</h3>
            <p>
							<span className="websuasion">websuasion</span> enabled Intrix to achieve their app development goals by providing multiple operating system app development.
            </p>
						<p>
							Are you looking for a professional app developer serving the Denver Colorado area to help transform your business? Let's schedule an introductory call.
						</p>
						<FormWrapper
		          modal
		          buttonProps={{
		            content: "Schedule An Introductory Call",
		            size: "tiny",
		            icon: "calendar alternate outline",
		          }}
		        >
		          <RequestMoreInfo />
		        </FormWrapper>
          </div>
        }
        textRight={
          <div>
					<img src="/images/case-studies/denver-colorado-app-development.jpg" className="rounded-image" alt="App Developer Denver Colorado - Websuasion" />
          </div>
        }
      />
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<p>
								<img src="/images/case-studies/intrix-technology-logo.webp" alt="App Developer Denver Colorado - Websuasion" />
							</p>
			        <p>
								Intrix Technology, Inc. has been an innovative provider of payment processing software for over 13 years with an industry reputation for providing high quality, market-driven solutions and consultation services to major market leaders.
							</p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
      <TextBlock
        textPadded
        textLeft={
          <div>
						<h2>Client Needs</h2>
						<ul>
							<li>Web portal for merchants and loan customers</li>
							<li>Mobile app</li>
						</ul>
          </div>
        }
        textRight={
          <div>
						<h2>websuasion Solutions</h2>
						<ul>
							<li>API development</li>
							<li>Application architecture</li>
							<li>iOS and Android mobile apps</li>
							<li>Merchant admin portal</li>
							<li>Loan customer portal</li>
						</ul>
          </div>
        }
      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Audio, Video, and Article Content Portal</h2>
							<p>
								Intrix Technology wanted to provide merchants with a mobile app their customers could use to make loan payments. Intrix contracted us to design the application architecture for, and subsequently build, a mobile payments application for iOS and Android, as well as their centralized API server.
							</p>
							<p>
			          <img src="/images/case-studies/intrix-technology-debt-repayment-app.jpg" alt="App Developer Denver Colorado - Websuasion" />
							</p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Application Architecture</h2>
							<p>
								We started with a highly detailed and iterative application architecture phase. Over the course of several meetings, we compiled a detailed list of specifications from which we generated process workflows and screen mockups for each part of the system. This included the mobile applications, the central API structure, database schema, merchant and customer web portals, and internal business logic. From the final application architecture document, we were able to provide a precise timeline and cost for the system.
							</p>
						<p>
							<img src="/images/case-studies/application-architecture.jpg" alt="App Developer Denver Colorado - Websuasion" />
						</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Merchant Admin Portal</h2>
							<p>
								The merchant admin portal was developed to provide merchants an efficient way to manage customers and loans. It included the importation of customer and loan information via spreadsheet files or manual creation, as well as editing functionality for customers and loans, transaction reporting, and administration account management.
							</p>
							<p>
			          <ReactPlayer
			            url= '/files/case-studies/intrix-admin-screencast.mp4'
			            width='100%'
			            height='100%'
			            controls = {true}
			          />
							</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Loan Customer Portal</h2>
							<p>
								The loan customer portal allowed new loan customers a way of initially setting up their accounts online. For convenience, it also provided many of the same payment options available through the mobile app.
							</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Ongoing Support</h2>
							<p>
								On a continual basis, we provide server maintenance and hosting of the existing site, as well as general tech consulting for business plans and analytics research.
							</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <h4>Let's Talk!</h4>
	            <p>"Data. Tech. Team." Those are the words we live by at websuasion. Our services foster growth and scalability for your business - all while maintaining your brand. We are an indispensable ally for you as you navigate online marketing. Let's talk. We'd love to know more about what you do and how we can solve your pain points.</p>
	            <RequestMoreInfo />
	          </div>
	        }
	      />
			</PageWrapper>
    </PageLayout>
  )
}

export default IndexPage
